// 联名卡logo
export const CO_BRAND_CARD_LOGO =
'https://img.ltwebstatic.com/images3_ccc/2024/06/20/8d/171885255347f540b64c2435864c9a352119a4fd2d.png'

// 联名卡logo缩略图
export const CO_BRAND_CARD_LOGO_THUMBNAIL =
'https://img.ltwebstatic.com/images3_ccc/2024/11/01/e7/17304432857a84dc80b71cae73d2f9faff24f14cdd.png'

// 联名卡权益文章链接
export const CO_BRAND_CARD_ARTICLE_URL = 'https://m.shein.com/mx/article-shein-card-benefit-rules.html'

// 卡bin属于联名卡的类型枚举，1：White，2：Indigo
export const CO_BRAND_LEVEL_LIST = [1, 2]
