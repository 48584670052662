import { getTaxesConfig } from 'public/src/pages/common/helpers.js'
import { htmlDecode } from '@shein/common-function'
import { template } from '@shein/common-function'
import { CO_BRAND_CARD_LOGO_THUMBNAIL } from '../page_tpls/token_pay/constant'

// apple pay 税费处理
export function apHandleTaxes(vm, taxes) {
  const language = vm.language || {}
  const { countryId, value } = vm.checkout?.default_address || {}
  // 是否是一单多税国家
  const isMultiTaxCountry = gbCommonInfo?.MULTIPLE_TAXES_SPLIT_DISPLAY?.includes(value)
  // ca站特殊处理
  const isShowCaGST = countryId === '38' || gbCommonInfo?.SiteUID == 'ca'
  const isBR = countryId == 30 || value == 'BR'
  const isMx = value == 'MX'
  const isTR = value == 'TR'

  const formatTaxes = (taxes = []) => {
    // 巴西站特殊处理
    // wiki.pageId=1167728623
    const rewriteConfig = isBR ? {
      3: {
        _name: 'ICMS',
        langKey: 'SHEIN_KEY_PWA_25432',
      }
    } : {}
    let taxTypeConfig = getTaxesConfig({ rewriteConfig })
    if (!isMultiTaxCountry) {
      taxTypeConfig[1] = { ...taxTypeConfig[1], langKey: isShowCaGST ? 'SHEIN_KEY_PWA_23975' : 'SHEIN_KEY_PWA_16154' }
    }
    if (isMx) {
      taxTypeConfig[7] = { ...taxTypeConfig[7], langKey: 'SHEIN_KEY_PWA_20399' }
    }
    if (isTR) {
      taxTypeConfig[7] = { ...taxTypeConfig[7], langKey: 'SHEIN_KEY_PWA_33619' }
    }
    return taxes.map(tax => ({
      label: language?.[taxTypeConfig?.[tax?.taxType]?.langKey] || '',
      amount: tax.taxPrice?.amount
    }))
  }

  const personalTaxes = formatTaxes(taxes.filter(tax => tax.taxPrice && tax.taxPrice.amount > 0 && tax.taxPayType == 2))
  let companyTaxes = formatTaxes(taxes.filter(tax => tax.taxPrice && tax.taxPrice.amount > 0 && tax.taxPayType == 1))
  // wiki.pageId=1204228351 巴西站特殊处理, 不展示公司税
  if (isBR) companyTaxes = []

  return {
    personalTaxes,
    companyTaxes
  }
}

const _apHandlePrimeData = (orderInfo) => {
  let { _allOrderGoodsList = [], orderGoodsList = [] } = orderInfo || {}
  const originList = !!_allOrderGoodsList.length ? _allOrderGoodsList : orderGoodsList
  let list = originList?.flat(Infinity) || []
  let primeGoods = list?.filter(item => item.is_prime_goods) || []
  if (!primeGoods.length) return {}
  const { product = {}, avgPrice = null, unitPrice = {} } = primeGoods?.[0] || {}
  // 差价、原价、实际售价
  let sale_price = avgPrice ? avgPrice : unitPrice || {}

  return { discount_price: product?.prime_goods_discount_price, retail_price: product?.retailPrice, sale_price, hasPrime: true }
}

const _apHandleXtraGoodsPrice = (orderInfo = {}) => {
  let { _allOrderGoodsList = [], orderGoodsList = [] } = orderInfo || {}
  const originList = !!_allOrderGoodsList.length ? _allOrderGoodsList : orderGoodsList
  let list = originList?.flat(Infinity) || []
  let xtraGoods = list?.filter(item => item.is_xtra_goods) || []
  if (!xtraGoods.length) return {}
  return +(xtraGoods?.[0]?.product?.salePrice?.amount) > 0 && xtraGoods?.[0]?.product?.salePrice || {}
}

// us RDF税费处理 wiki.pageId=1419326232
const _apHandleRDFTaxes = ({
  retail_delivery_fee = {},
  language = {},
  stateId = '' // 州id
} = {}) => {
  // MOCK
  // if (gbCommonInfo?.NODE_SERVER_ENV == 'gray') {
  //   retail_delivery_fee = {
  //     'amount': '16.80',
  //     'amountWithSymbol': '$16.80',
  //     'usdAmount': 16.8,
  //     'usdAmountWithSymbol': '$16.80'
  //   }
  // }
  const amountNotZero = +retail_delivery_fee?.amount > 0
  const rdfConfig = {
    show: amountNotZero,
    amount: amountNotZero ? retail_delivery_fee?.amount : '',
    title: language.SHEIN_KEY_PWA_21034 || ''
  }
  // US MN州特殊处理
  if (stateId == '64712') {
    rdfConfig.title = language.SHEIN_KEY_PWA_31925 || ''
  }
  return rdfConfig
}

// 切换pending
export const checkPending = ({
  billno,
  isGift,
  isBuyPrime
}) => {
  const { langPath, host } = gbCommonInfo
  return `${host}${langPath}/pay/result/pending?${isGift ? 'giftBillno' : 'billno'}=${billno}&buyPrimeOrder=${isBuyPrime ? 1 : 0}`
}

// 普通订单 下单页 applepay 金额明细数据处理
export function getPOLineItems(vm) {
  const price = vm.price || {}
  const language = vm.language || {}
  // abt, 参考需求https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1401323575
  const applePayCashShow = vm.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    return +price?.originPrice?.amount > 0 ? [{
      label: language.SHEIN_KEY_PWA_15098,
      amount: price.originPrice.amount
    }] : []
  }

  const { personalTaxes, companyTaxes } = apHandleTaxes(vm, price.tax || [])
  const rdfTaxInfo = _apHandleRDFTaxes({
    retail_delivery_fee: price?.retail_delivery_fee,
    language,
    stateId: vm?.checkout?.default_address?.stateId
  })

  const lineItems = [{
    label: language.SHEIN_KEY_PWA_15099,
    amount: price.newSubTotal.amount
  },
  {
    label: language.SHEIN_KEY_PWA_15100,
    amount: price?.actual_shipping_price?.amount
  }]


  if (vm.showInsurancePrice.length) {
    const obj = { label: vm.showReturnInsurance, amount: price.insurancePrice?.amount || 0 }
    lineItems.push(obj)
  }
  // 购买超省卡费用
  if (+price?.save_card_price?.amount > 0) {
    let obj = { label: language.SHEIN_KEY_PWA_26091, amount: price.save_card_price.amount || 0 }
    lineItems.push(obj)
  }
  // 购买会员价
  if (+price.prime_price?.amount > 0) {
    let obj = { label: language.SHEIN_KEY_PWA_21792, amount: price.prime_price.amount }
    lineItems.push(obj)
  }
  // us RDF消费税 CO州及MN州
  if (rdfTaxInfo.show) {
    const obj = { label: rdfTaxInfo.title, amount: rdfTaxInfo.amount }
    lineItems.push(obj)
  }
  if (price.handling_fee && (+price.handling_fee.amount > 0)) {
    const obj = { label: language.SHEIN_KEY_PWA_18625, amount: price.handling_fee.amount }
    lineItems.push(obj)
  }
  if (price.giftcard && price.giftcard.giftCardUsedPrice && price.giftcard.giftCardUsedCurrencyAmount > 0) {
    const obj = { label: language.SHEIN_KEY_PWA_16019, amount: '-' + price.giftcard.giftCardUsedPrice.amount }
    lineItems.push(obj)
  }
  if (vm.showDiscount) {
    const discountLabel = !!gbCommonInfo.IS_SUGGESTED ? language.SHEIN_KEY_PWA_30776 : language.SHEIN_KEY_PWA_15064
    const obj = { label: discountLabel, amount: '-' + vm.discountInfoPrice }
    lineItems.push(obj)
  }
  if (+price.pointPrice.amount > 0) {
    const obj = { label: language.SHEIN_KEY_PWA_15069, amount: '-' + price.pointPrice.amount }
    lineItems.push(obj)
  }
  // 付费会员优惠金额
  const primeAmount = price.prime_deduce_price?.amount || 0
  if (+primeAmount > 0) {
    let obj = { label: language.SHEIN_KEY_PWA_21795, amount: '-' + primeAmount }
    lineItems.push(obj)
  }
  if (price.onlinePayDiscountInfo && price.onlinePayDiscountInfo.onlinePayDiscountInfo && price.onlinePayDiscountInfo.onlinePayDiscountInfo.isEnjoyedDiscount == 1) {
    const obj = { label: language.SHEIN_KEY_PWA_24459, amount: '-' + price.onlinePayDiscountInfo.onlinePayDiscountInfo.discountPrice.amount }
    lineItems.push(obj)
  }
  if (+price.walletPrice.amount > 0) {
    const obj = { label: htmlDecode({ text: language.SHEIN_KEY_PWA_15707 }), amount: '-' + price.walletPrice.amount }
    lineItems.push(obj)
  }
  if (price.extraPromotion && price.extraPromotion.marketFission && price.extraPromotion.marketFission.isValidated == 1) {
    const obj = { label: language.SHEIN_KEY_PWA_16076, amount: '-' + price.extraPromotion.marketFission.amount.amount }
    lineItems.push(obj)
  }
  // 个人税费
  if (personalTaxes?.length) {
    lineItems.push(...personalTaxes)
  }
  lineItems.push({ label: language.SHEIN_KEY_PWA_15097, amount: price.grandTotalPrice.amount })
  // 公司税费
  if (companyTaxes?.length) {
    lineItems.push(...companyTaxes)
  }
  return lineItems
}

//普通订单 订单详情页 applepay 金额明细
export function getPOALineItems(vm) {
  const { language = {}, orderInfo = {}, abtInfo = {} } = vm || {}
  const primeGoodsPriceInfo = _apHandlePrimeData(orderInfo)
  const xtraGoodsPrice = _apHandleXtraGoodsPrice(orderInfo || {})
  const { personalTaxes, companyTaxes } = apHandleTaxes({
    language: vm.language,
    checkout: {
      default_address: {
        countryId: orderInfo.country_id,
        value: orderInfo.countryCode
      }
    }
  }, orderInfo.tax || [])
  const applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    return orderInfo?.retailTotallPrice?.amount > 0
      ? [
        {
          label: language.SHEIN_KEY_PWA_15098,
          amount: orderInfo.retailTotallPrice.amount
        }
      ]
      : []
  }

  const rdfTaxInfo = _apHandleRDFTaxes({
    retail_delivery_fee: orderInfo?.retail_delivery_fee,
    language,
    stateId: orderInfo?.shipping_state_id
  })


  const lineItems = [{
    label: language.SHEIN_KEY_PWA_15226,
    amount: primeGoodsPriceInfo?.hasPrime ? orderInfo?.normal_goods_total_price?.amount || 0 : orderInfo?.newSubTotalPrice?.amount || 0
  },
  {
    label: language.SHEIN_KEY_PWA_15100,
    amount: orderInfo.shippingPrice.amount
  }]
  // us RDF消费税 CO州及MN州
  if (rdfTaxInfo.show) {
    const obj = { label: rdfTaxInfo.title, amount: rdfTaxInfo.amount }
    lineItems.push(obj)
  }
  if (orderInfo.orderExtend.insurancePrice.amount > 0 || (orderInfo.insurance_type != 1 && orderInfo.isShippingInsuranceChecked == 1)) {
    let insTitle = language.SHEIN_KEY_PWA_16505
    if (orderInfo && orderInfo.insurance_type == 1) {
      insTitle = language.SHEIN_KEY_PWA_16930
    }

    const obj = { label: insTitle, amount: orderInfo.orderExtend?.insurancePrice?.amount || 0 }
    lineItems.push(obj)
  }

  // 超省卡包金额
  if (+xtraGoodsPrice?.amount > 0) {
    const obj = { label: language.SHEIN_KEY_PWA_26091, amount: xtraGoodsPrice?.amount || 0 }
    lineItems.push(obj)
  }

  if (+primeGoodsPriceInfo?.sale_price?.amount > 0) {
    let insTitle = language.SHEIN_KEY_PWA_23337

    const obj = { label: insTitle, amount: primeGoodsPriceInfo?.sale_price?.amount || 0 }
    lineItems.push(obj)
  }

  if (orderInfo.handling_fee && (+orderInfo.handling_fee.amount > 0)) {
    const obj = { label: language.SHEIN_KEY_PWA_18625, amount: orderInfo.handling_fee.amount }
    lineItems.push(obj)
  }

  if (orderInfo.couponPrice_without_free_shipping && +orderInfo.couponPrice_without_free_shipping.amount > 0) {
    const discountLabel = !!gbCommonInfo.IS_SUGGESTED ? language.SHEIN_KEY_PWA_30776 : language.SHEIN_KEY_PWA_15064
    const obj = { label: discountLabel, amount: '-' + orderInfo.couponPrice_without_free_shipping.amount }
    lineItems.push(obj)
  }

  if (orderInfo.pointPrice && +orderInfo.pointPrice.amount > 0) {
    const obj = { label: language.SHEIN_KEY_PWA_15069, amount: '-' + orderInfo.pointPrice.amount }
    lineItems.push(obj)
  }
  // 付费会员优惠金额
  const primeAmount = orderInfo.prime_discount_amount_vo?.amount || 0
  if (+primeAmount > 0) {
    let obj = { label: language.SHEIN_KEY_PWA_21795, amount: '-' + primeAmount }
    lineItems.push(obj)
  }
  if (orderInfo.onlinePayDiscountInfo && orderInfo.onlinePayDiscountInfo.isEnjoyedDiscount == 1 && orderInfo.onlinePayDiscountInfo.discount_price) {
    const obj = { label: language.SHEIN_KEY_PWA_24459, amount: '-' + orderInfo.onlinePayDiscountInfo.discount_price.amount }
    lineItems.push(obj)
  }
  if (orderInfo.usedWalletPrice && orderInfo.usedWalletPrice.amount > 0) {
    const obj = { label: language.SHEIN_KEY_PWA_15628, amount: '-' + orderInfo.usedWalletPrice.amount }
    lineItems.push(obj)
  }
  if (orderInfo.giftcard && orderInfo.giftcard.price) {
    const obj = { label: language.SHEIN_KEY_PWA_16019, amount: '-' + orderInfo.giftcard.price.amount }
    lineItems.push(obj)
  }
  if (orderInfo.extraPromotion && orderInfo.extraPromotion[0] && orderInfo.extraPromotion[0].type == 1 && orderInfo.extraPromotion[0].amount && orderInfo.extraPromotion[0].amount.amount > 0) {
    const obj = { label: language.SHEIN_KEY_PWA_16076, amount: '-' + orderInfo.extraPromotion[0].amount.amount }
    lineItems.push(obj)
  }
  // 个人税费
  if (personalTaxes?.length) {
    lineItems.push(...personalTaxes)
  }
  lineItems.push({ label: language.SHEIN_KEY_PWA_15212, amount: orderInfo.totalPrice.amount })
  // 公司税费
  if (companyTaxes?.length) {
    lineItems.push(...companyTaxes)
  }
  return lineItems
}


// 一键购订单 apple pay订单详情
export function getOCPLineItems(vm) {
  const { order = {}, language = {}, calculateMallInfo = {}, abtInfo = {} } = vm || {}
  const applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    // TODO：一键购暂时没有原价字段
    return []
  }

  const { personalTaxes } = apHandleTaxes({
    language,
    checkout: {
      default_address: {
        countryId: order.country_id,
        value: order.countryCode
      }
    }
  }, calculateMallInfo.tax || [])
  const onlinePayDiscountInfo = calculateMallInfo?.onlinePayDiscountInfo?.onlinePayDiscountInfo || {}
  let lineItems = [{
    'label': language.SHEIN_KEY_PWA_24015,
    'amount': calculateMallInfo.totalPrice?.amount
  }]

  if (calculateMallInfo.handling_fee && (+calculateMallInfo.handling_fee.amount > 0)) {
    lineItems.push({ label: language.SHEIN_KEY_PWA_23896, amount: calculateMallInfo.handling_fee.amount })
  }

  if (onlinePayDiscountInfo?.isEnjoyedDiscount == 1 && onlinePayDiscountInfo.discountPrice?.amount > 0) {
    lineItems.push({ label: language.SHEIN_KEY_PWA_23902, amount: '-' + onlinePayDiscountInfo.discountPrice.amount })
  }

  if (calculateMallInfo.extraPromotion?.marketFission?.isValidated == 1 && calculateMallInfo.extraPromotion?.marketFission?.amount?.amount > 0) {
    lineItems.push({ label: language.SHEIN_KEY_PWA_23903, amount: '-' + calculateMallInfo.extraPromotion?.marketFission?.amount?.amount })
  }

  // 个人税费
  if (personalTaxes?.length) {
    lineItems.push(...personalTaxes)
  }

  return lineItems
}


// 超省卡单独购一次下单 apple pay订单详情
export function getXtraLineItems(vm) {
  const { order = {}, language = {}, abtInfo = {} } = vm || {}
  const applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    // TODO：超省卡暂时没有原价字段
    return []
  }
  let lineItems = [
    {
      'label': language?.SHEIN_KEY_PWA_26091 || '',
      'amount': order?.goods_total?.amount || 0
    },
    {
      'label': language?.SHEIN_KEY_PWA_14955 || 'Total',
      'amount': order?.totalPrice?.amount || 0
    }
  ]
  // 存在税费则需要展示税费
  const taxPrice = order?.tax?.[0]?.taxPrice || {}
  if (Number(taxPrice?.amount) > 0) {
    lineItems.splice(1, 0, {
      'label': language?.SHEIN_KEY_PWA_21801 || 'Sales Tax',
      'amount': taxPrice.amount
    })
  }
  return lineItems
}

// 超省卡单独购订单详情 apple pay订单详情
export function getXtraAgainLineItems(vm) {
  const { order = {}, language = {}, abtInfo = {} } = vm || {}
  const applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    // TODO：超省卡暂时没有原价字段
    return []
  }
  let lineItems = [
    {
      'label': language?.SHEIN_KEY_PWA_26091 || '',
      'amount': order?.subTotalPrice?.amount || 0
    },
    {
      'label': language?.SHEIN_KEY_PWA_14955 || 'Total',
      'amount': order?.totalPrice?.amount || 0
    }
  ]
  const taxPrice = order?.tax?.[0]?.taxPrice || {}
  // 存在税费则需要展示税费
  if (Number(taxPrice?.amount) > 0) {
    lineItems.splice(1, 0, {
      'label': language?.SHEIN_KEY_PWA_21801 || 'Sales Tax',
      'amount': taxPrice?.amount
    })
  }
  return lineItems
}

/**
 * 替换支付成功页的referer
 *
 * @export
 */
export function replacePaySuccessReferer () {
  const { pathname = '' } = location || {}
  const orderListPath = `${gbCommonInfo?.langPath}/user/orders/list`
  if (pathname !== orderListPath) {
    history.replaceState(history.state ?? {}, null, orderListPath)
  }
}


/**
 * 判断站点是否支持联名卡权益
 * @param {Array<string>} supportSites 支持站点数组
 * @param {String} SiteUID - 当前数组
 * @returns 
 */
export const isSiteForCoBrandedCard = (supportSites, SiteUID) => supportSites && SiteUID && supportSites.includes(SiteUID)

/**
 * 判断支付方式是否展示联名卡权益
 * @param {Object} paymentCode 支付方式code
 * @param {Array<string>} isSupportInstallment - 是否支持卡分期
 * @returns 
 */
export const isPaymentForCoBrandedCard = (paymentCode, isSupportInstallment) => {
  const supportPayments = ['routepay-card']
  if (isSupportInstallment === 1) {
    supportPayments.push('routepay-cardinstallment')
  }
  return paymentCode && supportPayments.includes(paymentCode)
}

/**
 * 是否有联名卡权益
 * @param {*} coBrandQualificationInfo 联名卡资格信息
 * @returns {boolean} 
 */
export const checkCoBrandCardEligibility = (coBrandQualificationInfo = {}) => {
  const { has_used, qualification } = coBrandQualificationInfo
  // 使用过或有首单资格，展示权益
  return has_used === 1 || qualification === 1
}

/**
 * 是否有首单资格
 * @param {*} coBrandQualificationInfo 联名卡资格信息
 * @returns {boolean} 
 */
export const checkFirstOrderEligibility = (coBrandQualificationInfo = {}) => {
  const { has_used, qualification } = coBrandQualificationInfo
  return has_used !== 1 && qualification === 1
}

/**
 * 计算联名卡权益，站点和支付方式的支持情况 自行判断 ！！！
 * @param {*} coBrandQualificationInfo 联名卡资格信息
 * @param {*} rewardPoints 本单联名卡积分
 * @returns {number} 积分数
 */
export const calculateCoBrandedCardPoints = (
  coBrandQualificationInfo,
  rewardPoints = 0
) => {
  // 计算积分
  let sheinPoints = 0

  const hasCoBrandCardEligibility = checkCoBrandCardEligibility(coBrandQualificationInfo)
  if(!hasCoBrandCardEligibility)return 0

  // 未使用且有首单资格，加首单积分
  const hasFirstOrderEligibility = checkFirstOrderEligibility(
    coBrandQualificationInfo
  )
  if (hasFirstOrderEligibility && coBrandQualificationInfo?.first_order_point) {
    sheinPoints += coBrandQualificationInfo.first_order_point
  }

  // 加本单积分
  sheinPoints += rewardPoints

  return sheinPoints
}

/**
 * 转换联名卡权益文案
 * @param {*} points 联名卡获得积分数值，包含首单积分
 * @param {*} coBrandQualificationInfo 联名卡资格信息
 * @param {*} language 多语言
 * @returns {number} 积分数
 */
export const transformCoBrandCardRights = (points, coBrandQualificationInfo, language) => {
  if (!points) return null
  
  const hasCoBrandCardEligibility = checkCoBrandCardEligibility(coBrandQualificationInfo)
  if(!hasCoBrandCardEligibility)return null

  const isFirstOrderQualification = checkFirstOrderEligibility(coBrandQualificationInfo)

  if (isFirstOrderQualification) {
    const imgNode = `<img style="height: 0.32rem;margin:0 0.10666667rem;" src="${CO_BRAND_CARD_LOGO_THUMBNAIL}" />`
    return language.SHEIN_KEY_PWA_35065 + imgNode + template(points, language.SHEIN_KEY_PWA_35066)
  } else {
    return template(points, language.SHEIN_KEY_PWA_34929)
  }
}

